import { getCurrentInstance } from '@vue/composition-api'

/**
 * Returns ability result if ACL is configured or else just return true
 * Useful if you don't know if ACL is configured or not
 * Used in @core files to handle absence of ACL without errors
 * @param {String} action CASL Actions // https://casl.js.org/v4/en/guide/intro#basics
 * @param {String} subject CASL Subject // https://casl.js.org/v4/en/guide/intro#basics
 */
export const can = (action, subject) => {
  const vm = getCurrentInstance().proxy
  return vm.$can ? vm.$can(action, subject) : true
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewVerticalNavMenuLink = item => {
  if (item.meta !== undefined) {
    const userRole = localStorage.getItem('userRole')
    if (userRole === 'Administrator' && item.meta.allowed === 'admin') {
      return true
    }
    if (userRole === 'Administrator' || (userRole === 'Administrator-BR' && item.meta.allowed === 'tax-office')) {
      return true
    }
  } else {
    return true
  }
  return false
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource & Hide group if all of it's children are hidden
 * @param {Object} item navigation object item
 */
// eslint-disable-next-line arrow-body-style
export const canViewVerticalNavMenuGroup = () => true

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewVerticalNavMenuHeader = item => {
  if (item.meta !== undefined) {
    const userRole = localStorage.getItem('userRole')
    if (userRole === 'Administrator' && item.meta.allowed === 'admin') {
      return true
    }
    if (userRole === 'Administrator' || (userRole === 'Administrator-BR' && item.meta.allowed === 'tax-office')) {
      return true
    }
  } else {
    return true
  }
  return false
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewHorizontalNavMenuLink = () => true

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource
 * @param {Object} item navigation object item
 */
export const canViewHorizontalNavMenuHeaderLink = () => true

/**
 * Check if user can view item based on it's ability
 * Based on item's action and resource & Hide group if all of it's children are hidden
 * @param {Object} item navigation object item
 */
// eslint-disable-next-line arrow-body-style
export const canViewHorizontalNavMenuGroup = () => true

// eslint-disable-next-line arrow-body-style
export const canViewHorizontalNavMenuHeaderGroup = () => true
